import React from 'react'

import Layout from '../components/layout'
import ContactForm from '../components/contactForm'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-contact.jpg'
import forBizSvg from '../images/contact-us.svg'

const ContactUsPage = () => (
  <Layout>
    <PageHelmet
      title="Contact Us"
      description="We look forward to hearing from you"
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'PLEASE'}
      accentedTitle={'Contact Us'}
      subtitle={'We look forward to hearing from you'}
    />

    <section className="section mow-border-bottom" key="mow-contactus-form">
      <div className="container has-text-centered">
        <h2 classname="title is-2">Get In Touch</h2>
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactUsPage
