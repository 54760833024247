import React, { useState } from 'react'
import axios from 'axios'
import classnames from 'classnames'
import { useFormik } from 'formik'

const contactFormEndpoint =
  'https://1umtu6ohkc.execute-api.us-east-1.amazonaws.com/contact-form'
const submitToContactForm = (name, email, message) => {
  return axios.post(contactFormEndpoint, { name, email, message })
}

const MailChimpForm = () => {
  const [message, setMessage] = useState('')
  const [isAjaxActive, setIsAjaxActive] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const formik = useFormik({
    initialValues: { name: '', email: '', message: '' },
    onSubmit: values => {
      const { name, email, message } = values
      setIsAjaxActive(true)
      submitToContactForm(name, email, message)
        .then(response => {
          setMessage(response.data.msg)
          setIsAjaxActive(false)
          setIsSubmitDisabled(true)
        })
        .catch(error => {
          setMessage(`${error.data.msg}`)
          setIsAjaxActive(false)
        })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} className="submit-form">
      <div className="field">
        <label className="label" htmlFor="name"></label>
        <div className="control">
          <input
            className="input"
            onChange={formik.handleChange}
            type="text"
            placeholder="Name"
            value={formik.values.name}
            name="name"
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="email"></label>
        <div className="control">
          <input
            className="input"
            onChange={formik.handleChange}
            placeholder="Email"
            type="email"
            value={formik.values.email}
            name="email"
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="message"></label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder="Message"
            onChange={formik.handleChange}
            value={formik.values.message}
            name="message"
          ></textarea>
        </div>
      </div>
      <button
        type="button"
        value="Subscribe"
        name="subscribe"
        id="mc-embedded-subscribe"
        disabled={isSubmitDisabled}
        onClick={formik.handleSubmit}
        className={classnames('button', { 'is-loading': isAjaxActive })}
      >
        Send Message
      </button>

      <div dangerouslySetInnerHTML={{ __html: message }} />
    </form>
  )
}

export default MailChimpForm
